
import CompanyNumberField from '@/components/FormComponents/CompanyNumberField.vue';
import TextField from '@/components/FormComponents/TextField.vue';
import KnowsUsFromSelect from '@/components/KnowsUsFromSelect/KnowsUsFromSelect.vue';
import TooltipVideoWrapper from '@/components/TooltipVideoWrapper.vue';
import { vatStatuses } from '@/config/constants';
import { reportError } from '@/helpers/logging';
import { DeepNullable } from '@/helpers/types';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { lookupCompanynumberMixin } from '@/mixins/lookupCompanynumberMixin';
import { companyLookup } from '@/services/customer';
import {
    completeRegistrationVakantiewoningVerhuurder,
    IProfessionalactivityGetAllViewModel,
    IProfessionalactivityViewModel,
    ISocialsecretariatViewModel,
    VATType
} from 'dexxter-types';
import { PropOptions } from 'vue';
import mixins from 'vue-typed-mixins';

export default mixins(lookupCompanynumberMixin).extend({
    components: { TooltipVideoWrapper, CompanyNumberField, TextField, KnowsUsFromSelect },
    mixins: [formRulesMixin, lookupCompanynumberMixin],

    props: {
        value: {
            required: true
        } as PropOptions<DeepNullable<completeRegistrationVakantiewoningVerhuurder>>,
        socialSecretariats: {
            required: true
        } as PropOptions<ISocialsecretariatViewModel[]>,
        professionalactivities: {
            required: true
        } as PropOptions<IProfessionalactivityGetAllViewModel[]>,
        formValid: {
            required: true,
            type: Boolean
        } as PropOptions<boolean>
    },

    data() {
        return {
            videoTooltips: [],
            vatLiabelItems: [
                {
                    value: vatStatuses.VATLIABLE,
                    label: this.$i18n.t('completeUserRegistration.VATLiable')
                },
                {
                    value: vatStatuses.NOTVATLIABLE,
                    label: this.$i18n.t('completeUserRegistration.NotVATLiable')
                },
                {
                    value: vatStatuses.VATEXEMPT,
                    label: this.$i18n.t('completeUserRegistration.VATExempt')
                }
            ] as { value: VATType; label: string }[],
            submitDialog: false,
            selectedMainProfessionalactivityId: null as null | number
        };
    },

    computed: {
        isFormValid: {
            get(): boolean {
                return this.formValid;
            },
            set(value: boolean) {
                this.$emit('update:formValid', value);
            }
        },
        registrationData: {
            get(): DeepNullable<completeRegistrationVakantiewoningVerhuurder> {
                return this.value;
            },
            set(value: DeepNullable<completeRegistrationVakantiewoningVerhuurder>) {
                this.$emit('input', value);
            }
        },
        eligableProfessionalactivities(): IProfessionalactivityGetAllViewModel[] {
            if (!this.professionalactivities) {
                return [];
            }

            return this.professionalactivities.filter((_activity) => !_activity.VATExemptCategory);
        },
        mainActivities(): IProfessionalactivityGetAllViewModel[] {
            if (!this.eligableProfessionalactivities) {
                return [];
            }
            return [...this.eligableProfessionalactivities].sort((a, b) => (a.name > b.name ? 1 : -1));
        },
        subActivities(): IProfessionalactivityViewModel[] {
            if (!this.selectedMainProfessionalactivityId) {
                return [];
            }

            const found = this.eligableProfessionalactivities.find(
                (activity) => activity.id === this.selectedMainProfessionalactivityId
            );

            if (!found) {
                return [];
            }

            return found.subActivities;
        }
    },

    methods: {
        handleChangeKnowsUsValue(value: string | null) {
            this.registrationData.knowsUsFrom = value;
        },
        async lookupCompany(): Promise<void> {
            try {
                const companyNumber = this.registrationData.company?.number;

                if (!companyNumber) {
                    return;
                }

                const result = await companyLookup(companyNumber);

                this.fillInMissingCompanyInfoBasedOnLookup(this.registrationData, result);
            } catch (e) {
                reportError(e);
            }
        }
    }
});
